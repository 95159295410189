import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Destination from "./pages/Destination";
import GoldenTriangle from "./pages/Goldentriangle";
import SouthIndia from "./pages/SouthIndia";
import Kerala from "./pages/Kerala";
import Gujarat from "./pages/Gujarat";
import Bhubnaeswar from "./pages/Bhubnaeswar";
import Delhi from "./pages/Delhi";
import Himanchal1 from "./pages/Himanchal";
import Himanchal2 from "./pages/Himanchal2";
import Himanchal3 from "./pages/Himanchal3";
import Maharaja from "./pages/Maharaja";
import Orissa from "./pages/Orissa";
import Orissa2 from "./pages/Orissa2";
import Spiritual from "./pages/Spiritual";
import Spiritual2 from "./pages/Spiritual2";
import Ladakh from "./pages/Ladakh";
import Navbar2 from "./components/Navbar2";
import Footer from "./components/Footer";
import Reviews from "./pages/Reviews";
import Blogs from "./pages/Blogs";
import ReactGA from "react-ga4";
import CookieConsentBanner from "./components/CookieConsentBanner";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const TRACKING_ID = "G-D7QB9H845V";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({ hitType: "pageview", page: `/${document.location.pathname}` });

  return (
    <div>
      <Navbar2 />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/destinations" element={<Destination />} />
        <Route
          path="/destinations/goldentriangle"
          element={<GoldenTriangle />}
        />
        <Route path="/destinations/southindia" element={<SouthIndia />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/destinations/kerala" element={<Kerala />} />
        <Route path="/destinations/gujarat" element={<Gujarat />} />
        <Route path="/destinations/bhubaneswar" element={<Bhubnaeswar />} />
        <Route path="/destinations/delhi" element={<Delhi />} />
        <Route path="/destinations/himanchal1" element={<Himanchal1 />} />
        <Route path="/destinations/himanchal2" element={<Himanchal2 />} />
        <Route path="/destinations/himanchal3" element={<Himanchal3 />} />
        <Route path="/destinations/maharaja" element={<Maharaja />} />
        <Route path="/destinations/odhisa" element={<Orissa />} />
        <Route path="/destinations/tribal" element={<Orissa2 />} />
        <Route path="/destinations/spiritual1" element={<Spiritual />} />
        <Route path="/destinations/spiritual2" element={<Spiritual2 />} />
        <Route path="/destinations/ladakh" element={<Ladakh />} />
        <Route path="/blogs" element={<Blogs />} />
      </Routes>
      <CookieConsentBanner />
      <Footer />
    </div>
  );
}

export default App;
