import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Bhubnaeswar = () => {
  return (
    <div className="golden-triangle bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">
        Visite de l'Odisha et du Jharkhand
      </h1>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/bhubaneswar.jpg"
            alt="Day 7"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 1 : Arrivée à Bhubaneswar et visite de la ville
        </h2>
        <p className="text-gray-700">
          Le premier jour, après votre arrivée à Bhubaneswar, vous aurez
          l'occasion d'explorer la ville. Commencez par visiter les attractions
          principales telles que le temple Lingaraj, l'un des plus célèbres
          temples de Shiva en Inde, le temple Mukteshwar, un joyau
          architectural, le temple Rajarani, connu pour son architecture
          exquise, et le musée d'État d'Odisha, où vous pourrez découvrir
          l'histoire et la culture de la région.
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/udaigiri.jpg"
            alt="Day 7"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 2 : Udaygiri, Khandagiri et temples
        </h2>
        <p className="text-gray-700">
          Le deuxième jour, vous visiterez les grottes d'Udaygiri et de
          Khandagiri, qui abritent des sculptures et des inscriptions anciennes.
          Ces grottes monastiques offrent un aperçu fascinant de la vie et de la
          spiritualité anciennes. Ensuite, vous pourrez explorer les temples
          voisins tels que le temple de Parasurameswara, un exemple magnifique
          de l'architecture kalinga, le temple d'Ananta Vasudeva, dédié à
          Guide, et le temple de Brahmeshwara, qui présente des sculptures
          exquises.
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/bhubaneswar2.jpg"
            alt="Day 7"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 3 : Nuit à Bhubaneswar</h2>
        <p className="text-gray-700">
          Profitez d'une deuxième nuit à Bhubaneswar pour continuer à découvrir
          la ville. Vous pourriez visiter d'autres temples importants tels que
          le temple Rajarani, le temple Mukteshwar, ou simplement vous promener
          dans les rues animées de la vieille ville pour explorer les marchés
          locaux et goûter à la cuisine traditionnelle de l'Odisha.
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/puri.jpg"
            alt="Day 7"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 4 : Visite de Puri</h2>
        <p className="text-gray-700">
          Le quatrième jour, vous vous rendrez à Puri, une ville sainte célèbre
          pour le temple de Jagannath. Une fois à Puri, vous aurez l'occasion de
          visiter le temple de Jagannath, un important lieu de pèlerinage
          hindou, et d'admirer son architecture grandiose. Ensuite, profitez du
          charme de la plage de Puri et de ses magnifiques couchers de soleil.
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/chilika.jpg"
            alt="Day 7"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 5 : Puri/Chilika/Gopalpur
        </h2>
        <p className="text-gray-700">
          Ce jour-là, vous ferez une excursion au lac Chilika, le plus grand lac
          salé d'Asie, situé à proximité de Puri. Vous pourrez faire une
          croisière en bateau pour observer les oiseaux migrateurs et la vie
          aquatique, ainsi que visiter des îles pittoresques telles que l'île de
          Nalabana. Ensuite, vous vous rendrez à Gopalpur, où vous pourrez vous
          détendre sur la plage et profiter de la tranquillité de cet endroit
          serein.
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/gopalpur.jpg"
            alt="Day 7"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 6 : Gopalpur/Batagoda (En route visite d'un village tribal)
        </h2>
        <p className="text-gray-700">
          En route vers Batagoda, vous aurez l'occasion de faire une visite d'un
          village tribal pour découvrir la culture, les traditions et le mode de
          vie des habitants tribaux de la région. Vous pourrez interagir avec
          les habitants, en apprendre davantage sur leurs traditions
          artisanales, et admirer leurs arts et métiers uniques.
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/rayagadh.jpg"
            alt="Day 7"
            style={styles.image}
          />
        </div>

        <h2 className="text-xl font-bold mt-2">
          Jour 7 : Batagoda/Marché des Kutia Kondha/Marché de Kotgarh/Rayagada
        </h2>
        <p className="text-gray-700">
          En route vers Batagoda, vous aurez l'occasion de faire une visite d'un
          village tribal pour découvrir la culture, les traditions et le mode de
          vie des habitants tribaux de la région. Vous pourrez interagir avec
          les habitants, en apprendre davantage sur leurs traditions
          artisanales, et admirer leurs arts et métiers uniques.
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/jeypore.jpg"
            alt="Day 7"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 8 : Rayagada/Marché ONKUDELLI/Jaypore
        </h2>
        <p className="text-gray-700">
          Le huitième jour, vous visiterez le marché d'ONKUDELLI, qui est un
          marché coloré et animé où vous pourrez découvrir l'artisanat local et
          les produits traditionnels. Ensuite, vous vous rendrez à Jaypore, une
          ville réputée pour son artisanat tribal et ses textiles.
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/lohandiguda.jpg"
            alt="Day 7"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mb-2 truncate">
          Jour 9: Jaypore/Kotpad/Lohandiguda/Jagdalpur
        </h2>
        <p className="text-gray-700">
          Ce jour-là, vous visiterez Kotpad, connu pour ses tissus naturels et
          ses motifs tribaux uniques. Ensuite, vous vous rendrez à Lohandiguda
          pour explorer les villages tribaux et découvrir leur mode de vie
          traditionnel. Enfin, vous arriverez à Jagdalpur pour y passer la nuit.
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/mardum.jpg"
            alt="Day 7"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 10 : Jagdalpur/Mardum/Chutes de Chitrakut/Jagdalpur
        </h2>
        <p className="text-gray-700">
          Le dixième jour, vous visiterez Mardum, un village tribal, pour
          découvrir leur culture, leurs coutumes et leurs danses folkloriques.
          Ensuite, vous vous rendrez aux magnifiques chutes de Chitrakut, un
          endroit pittoresque entouré de verdure luxuriante. Vous retournerez
          ensuite à Jagdalpur pour y passer la nuit.
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/kanker.jpg"
            alt="Day 7"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 11 : Jagdalpur/Kondagaon/Kanker
        </h2>
        <p className="text-gray-700">
          Ce jour-là, vous visiterez Kondagaon, célèbre pour ses objets
          d'artisanat et ses œuvres d'art tribales. Ensuite, vous vous dirigerez
          vers Kanker pour la prochaine étape de votre voyage.
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/pataleshwar.png"
            alt="Day 7"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 12 : Kanker/Usine de cigarettes de Bilaspur/Temple de Pali
          Shiva/Temple de Malahar/Temple de Pataleshwar/Champ de Shakti de
          Ratanpur/Shivrinarayan
        </h2>
        <p className="text-gray-700">
          Ce jour-là, vous visiterez Kondagaon, célèbre pour ses objets
          d'artisanat et ses œuvres d'art tribales. Ensuite, vous vous dirigerez
          vers Kanker pour la prochaine étape de votre voyage.
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/shivnaryan.jpg"
            alt="Day 7"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 13 : Nuit à Shivrinarayan
        </h2>
        <p className="text-gray-700">
          DVous passerez une nuit à Shivrinarayan pour profiter de l'atmosphère
          paisible de cet endroit.
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/bhoramedo.jpg"
            alt="Day 7"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 14 : Visite du marché Cilpi et des villages Baiga, nuitée à
          Bhoramdeo
        </h2>
        <p className="text-gray-700">
          Ce jour-là, vous visiterez le marché Cilpi pour découvrir l'artisanat
          local et les produits régionaux. Ensuite, vous explorerez les villages
          Baiga, où vous pourrez en apprendre davantage sur leur culture et
          leurs traditions. Vous passerez la nuit à Bhoramdeo.
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img
            src="/tours/raipur.jpg"
            alt="Day 7"
            style={styles.image}
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 15 : Bhoramdeo/Marché de Teregaon/Raipur
        </h2>
        <p className="text-gray-700">
          <p>Départ de Bhoramdeo .</p>
          <p>
            Le seizième jour, vous visiterez le marché de Teregaon pour
            découvrir l'artisanat et les produits locaux. Ensuite, vous vous
            rendrez à Raipur, la capitale de l'État du Chhattisgarh, où vous
            pourrez visiter des sites historiques et profiter de la vie urbaine.
          </p>
        </p>
      </div>

      <div className="day my-4 p-4 bg-white rounded-lg shadow">
        <div className="image-wrapper flex justify-center items-center">
          <img
            src="/tours/departure.jpg"
            alt="Day 1"
            className="rounded-lg h-auto md:w-1/2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">
          Jour 16 : Visite de Raipur et départ
        </h2>
        <p className="text-gray-700">
          Le dernier jour, vous aurez l'occasion de visiter les attractions de
          Raipur, y compris le Mahant Ghasidas Museum, le temple de Hatkeshwar,
          et le temple de Champaran. Ensuite, vous prendrez votre vol de départ
          depuis Raipur.
        </p>
      </div>
    </div>
  );
};

export default Bhubnaeswar;
