import React, { useState } from 'react';
import './CookieConsentBanner.css';

const CookieConsentBanner = () => {
  const [isAccepted, setIsAccepted] = useState(false);

  const handleAccept = () => {
    setIsAccepted(true);
    localStorage.setItem('cookies-accepted', 'true');
  };

  const handleDecline = () => {
    setIsAccepted(true);
    localStorage.setItem('cookies-accepted', 'false');
  };

  // Check if cookies are already accepted
  if (localStorage.getItem('cookies-accepted')) {
    return null; // Don't show the banner if cookies have already been accepted
  }

  return (
    <div className="cookie-consent-banner">
      <p>
        Ce site utilise des cookies pour améliorer votre expérience. En cliquant sur "Accepter tout", vous acceptez notre utilisation des cookies.
      </p>
      <div className="button-container">
        <button onClick={handleAccept} className="accept-button">Accepter tout</button>
        <button onClick={handleDecline} className="decline-button">Refuser</button>
      </div>
    </div>
  );
};

export default CookieConsentBanner;
