import React from "react";

const styles = {
  imageWrapper: {
    width: "90%",
    height: "400px",
    overflow: "hidden",
    position: "relative",
    margin: "auto", // For horizontal centering
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Ladakh = () => {
  return (
    <div className="bg-gray-100 p-4 m-2">
      <h1 className="text-4xl font-bold mb-4 text-center">Circuit de Leh Laddak!</h1>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/delhi.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 1 : Arrivée à Delhi</h2>
        <p>
          Accueil à l’aéroport de Delhi le matin par Guide et transfert à
          l’hôtel. Après un rafraichissement, découverte de la capitale pour se
          mettre dans l’ambiance indienne ! Visite du Lotus Temple qui accueille
          toutes les religions dans un même lieu de recueillement. Vibrations
          intenses. Balade en fin de journée dans les bazars animés du vieux
          Delhi. Nuit à l’hôtel.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/amritsar.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 2 : Delhi – Amritsar </h2>
        <p>
          Tôt le matin, transfert vers la gare de Delhi et train pour Amritsar,
          qui signifie «Océan de Nectar». Ce surnom provient du lac sacré qui
          abrite le célèbre Temple d’Or. Arrivée et transfert à notre hôtel.
          Après le déjeuner, visite du temple Sikh. Ce temple est le centre
          névralgique de la vieille ville merveilleusement restaurée. Situé au
          milieu du lac, il abrite un prêtre qui lit à longueur de journée la
          parole en punjabi de Granth Sahib, le livre sacré. Puis route vers la
          frontière indo-pakistanaise (30 km), à l’heure de la fermeture des
          portes. Un spectacle incroyable vous y attend ! Retour en ville et
          possibilité de visiter le temple de nuit, autre ambiance et autre
          atmosphère. Nuit à l’hôtel.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/dharamshala.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 3 : Amritsar – Dharamsala</h2>
        <p>
          Nous attaquons tranquillement la montagne aujourd’hui afin d’admirer
          les paysages grandioses que nous offre l’Himalaya. Nous arrivons ainsi
          à Dharamsala (ou plutôt Mac Leod Ganj), siège du gouvernement tibétain
          en exil. Cette station de montagne nous amène au-delà des frontières
          de par sa culture bouddhiste. Nuit à l’hôtel.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/dharamshala2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 4 : Dharamsala</h2>
        <p>
          Visite du monastère où le Dalaï Lama a coutume de donner ses
          enseignements, puis balade à pied en commençant par le village de
          Bhagsu et son temple aux sources naturelles (baignade possible mais
          l’eau est froide !). Excursion jusqu’à la cascade. Escapade en forêt
          et visite du village de Dharamkot. Possibilité d’effectuer une journée
          de trekking (En option : à voir sur place). Nuit à l’hôtel.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/manali.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 5 : Dharamsala – Manali </h2>
        <p>
          Nous entrons aujourd’hui au cœur de l’Himalaya avec des paysages
          époustouflants à chaque virage ! Sur ce trajet, nous ne manquerons pas
          de faire une première pause à Palampur pour admirer les plantations de
          thé avec la chaine himalayenne en fond de toile. Autre arrêt à Kullu
          pour gravir les marches menant au petit temple de Bijli Mahadev (2h
          aller/retour) d’où l’on jouit d’une vue imprenable sur les 3 vallées.
          Arrivée à Manali en fin de journée et installation à l’hôtel pour la
          nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/manali2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 6 : Manali</h2>
        <p>
          Située à 2000 m d’altitude, cette bourgade invite à la balade avec la
          découverte du temple très spécial d’Hadimba. Continuation à pied vers
          le vieux Manali puis nous passons sur l’autre versant pour découvrir
          le village pittoresque de Vashisht, son vieux temple abritant des
          bains chauds, et excursion vers les cascades de Jogini. Retour à
          l’hôtel pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/jispa.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 7 : Manali – Jispa ou Sarchu</h2>
        <p>
          C’est le grand départ pour le Ladakh aujourd’hui ! Nous attaquons
          cette journée très tôt le matin par la périlleuse mais belle ascension
          du col de Rhotang (4 200 m), route en lacets à n’en plus finir. Petit
          arrêt au sommet pour découvrir tous ces touristes indiens venus
          admirer la neige pour la première fois ! Arrivée à Jispa ou Sarchu en
          fonction des disponibilités des logements et installation en guest
          house ou en camp pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/tso.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 8 : Jispa ou Sarchu – Tso Kar Lake</h2>
        <p>
          Poursuite de notre route vers le nord à travers des gorges
          impressionnantes. En route, découverte de quelques gompas (monastères
          bouddhistes). Nous nous enfonçons petit à petit dans ce désert
          himalayen. Nous quittons ensuite l’axe principal Manali/Leh et
          continuons notre parcours sur une piste. Nous entrons maintenant dans
          la région du Rupshu, refuge des marmottes, ânes sauvages, ibex et
          léopards des neiges. Gardez l’œil ouvert, on ne sait jamais ! Arrivée
          autour de ce lac salé planté dans un décor lunaire à couper le souffle
          ! Nuit en camp fixe.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/tso2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 9: Tso Kar – Tso Moriri</h2>
        <p>
          Nous profitons de cette « courte » étape pour faire le plein d’images
          et récupérer de la veille… Passage d’un col à 4 900 m avant de
          redescendre vers ce superbe lac de haute montagne aux eaux turquoise.
          En fin de journée, visite du village de Korzok, l’un des seuls de la
          région du Rupshu, et de son petit monastère. Pose « chai » dans une
          cantine locale (si elle est ouverte !) et rencontre avec les nomades
          Champas. Nuit en camp fixe.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/leh.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 10 : Tso Moriri - Leh</h2>
        <p>
          De préférence tôt le matin, nous poursuivons notre route pour
          retrouver la vallée de l’Indus. Nous longeons ensuite sa rivière sur
          50 km pour rejoindre Leh, capitale du Ladakh. En route, visite des
          monastères de Hemis, le plus grand du Ladakh, et de Thikse, édifié sur
          un éperon rocheux dominant le village. Installation en guest house
          pour une nuit bien méritée !
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/khar.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 11 : Leh – Khardung La</h2>
        <p>
          Départ pour le Kardung La, plus haut col carrossable au monde ! Vous
          serez accueillis par le « clappement » des drapeaux de prières
          flottant sur un panorama sensationnel ! En fond de décor, on découvre
          la grande chaîne du Zanskar au sud et le Karakoram au nord ! Puis nous
          descendons sur la vallée de la Nubra comme si nous atteignons le bout
          du monde ! Un désert de sable tapisse son centre où les derniers
          chameaux sauvages se baladent. Nuit en guest house en en camp.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/nubra.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 12 : Nubra Valley – Leh</h2>
        <p>
          Ce matin, nous partons explorer cette vallée à pied de préférence.
          Retour ensuite sur Leh et temps libre en fin de journée si vous
          souhaitez vous balader ou faire un peu de shopping. Retour en guest
          house pour la nuit.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/leh2.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 13 : : Leh – Delhi</h2>
        <p>
          Dans la matinée, transfert vers l’aéroport de Leh et vol retour pour
          Delhi. Vu du ciel, l’Himalaya paraît être une chaine infinie. Arrivée
          dans la tourbillonnante capitale, Delhi, et transfert à l’hôtel. Nous
          pouvons nous rendre dans l’après-midi au Qutub Minar, vestige de la
          période Moghol et sa tour haute de 72,5 m ou encore au Gurudwara
          Bangla Sahib, deuxième lieu de pèlerinage Sikh après Amritsar, ou
          visiter le Red Fort. Bref, un tas d’options à voir avec Guide une
          fois sur place ! Nuit à l’hôtel.
        </p>
      </div>

      <div className="my-4 p-4 bg-white rounded-lg shadow">
        <div style={styles.imageWrapper}>
          <img style={styles.image}
            src="/tours/departure.jpg"
            alt="my-4 p-4 bg-white rounded-lg shadow 2"
          />
        </div>
        <h2 className="text-xl font-bold mt-2">Jour 14 : Delhi – Aéroport international</h2>
        <p>
          Après le petit déjeuner, Guide vous accompagnera à l’aéroport Indira
          Gandhi pour votre vol retour avec Air India. FIN DE NOS SERVICES.
        </p>
      </div>
    </div>
  );
};

export default Ladakh;
