import React from "react";
import { Link } from "react-router-dom";

const YogaTourComponent = () => {
  return (
    <div
      className="bg-cover bg-center m-6 h-auto md:h-96 flex items-center justify-center" // Responsive height classes added
      style={{ backgroundImage: 'url("yogatravel.jpg")' }} // Replace with the actual path to your yoga background image
    >
      <div className="bg-opacity-75 p-8 rounded-lg bg-gray-800 text-white text-center">
        <h2 className="text-4xl font-bold mb-4">
          Retraite de yoga en Inde : Rishikesh
        </h2>
        <p className="text-lg mb-8">
          Participez à notre retraite de yoga guidée à Rishikesh. Notre retraite
          de yoga propose deux cours, l'un le matin et l'autre le soir, avec un
          professionnel du yoga. Vous séjournerez également dans un hébergement
          confortable et serez guidé lors d'un voyage à KunjaPuri et Haridwar.
          Vous pouvez également personnaliser la retraite de yoga comme vous le
          souhaitez. Pour en savoir plus, contactez-nous.
        </p>
        <Link to="/contact">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
            Réservez maintenant
          </button>
        </Link>
      </div>
    </div>
  );
};

export default YogaTourComponent;
