import React from "react";

const AboutComponent = () => {
  return (
    <div className="bg-gray-100  flex flex-col justify-center items-center p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold text-center text-indigo-700 mb-4">
          Vacances en Inde avec Guide francophone en Inde Namaste
        </h1>
        <h2 className="text-2xl font-bold text-center text-indigo-600 mb-6">
          A propos de nous
        </h2>
        <p className="text-lg text-justify leading-relaxed">
          Faites votre voyage en Inde plus facilement avec nos services de
          guide. Nous sommes un fournisseur de services de guide en Inde, et
          notre objectif principal est d'offrir à nos clients l'expérience la
          plus authentique et la meilleure dans ce pays. Nous fournissons des
          services de guide aux voyageurs francophones et anglophones visitant
          l'Inde, et nous pouvons également fournir des services de véhicule et
          d'hébergement.
        </p>
        <div className="flex justify-center mt-4">
          <a
            href="/about"
            className="text-white bg-indigo-500 px-6 py-2 rounded-full hover:bg-indigo-700 transition duration-300 ease-in-out"
          >
            Cliquez ici pour plus d'informations
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
